import InsertScriptFilter from './InsertScriptFilter';
import ScriptTagFilter from './ScriptTagFilter';
import WrapperFilter from './WrapperFilter';
import LocalCookieFilter from './LocalCookieFilter';
import Interface from './Interface';
import Configuration from './Configuration';

export default class CookieConsent {

  init(configObject) {
    const UI = new Interface();

    const insertScriptFilter = new InsertScriptFilter();
    const scriptTagFilter = new ScriptTagFilter();
    const wrapperFilter = new WrapperFilter();
    const localCookieFilter = new LocalCookieFilter();

    document.addEventListener("CCConfigSet", function () {

      

      insertScriptFilter.init();
      scriptTagFilter.init();
      wrapperFilter.init();
      localCookieFilter.init();


      window.CookieConsent.wrapperFilter = wrapperFilter;

      UI.buildInterface(() => {
        UI.addEventListeners();

        if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl !== undefined){
          wrapperFilter.reRunCallbacks();
        }
      });
    })
    new Configuration(configObject);


    UI.buildIframeInterface(() => {
      UI.addIframeEventListeners();
    });
  }
}