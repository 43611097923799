import { el, mount } from 'redom';
import Language from './Language';
import Utilities from "./Utilities";

export default class Interface {

  constructor() {
    this.elements = {};
  }


  buildStyle() {
    return el('style',
      '#cconsent-bar, #cconsent-bar * { box-sizing:border-box }',
      '#cconsent-bar { background-color:' + window.CookieConsent.config.theme.barColor + '; color:' + window.CookieConsent.config.theme.barTextColor + '; padding:15px; text-align:right; font-family:sans-serif; font-size:14px; line-height:18px; position:fixed; bottom:0; left:0; width:100%; z-index:9998; transform: translateY(0); transition: transform .6s ease-in-out; transition-delay: .3s;}',
      '#cconsent-bar.ccb--hidden {transform: translateY(100%); display:block;}',
      '#cconsent-bar .ccb__wrapper { display:flex; justify-content:space-between; max-width:1800px; margin:0 auto;}',
      '@media (max-width: 800px) { #cconsent-bar .ccb__wrapper {flex-wrap: wrap;}}',
      '#cconsent-bar .ccb__left { align-self:center; text-align:left; margin: 15px 0;}',
      '#cconsent-bar .ccb__right { align-self:center; white-space: nowrap; flex:1;}',
      '#cconsent-bar .ccb__right > div {display:inline-block; color:#FFF;}',
      '#cconsent-bar a { text-decoration:underline; color:' + window.CookieConsent.config.theme.barTextColor + '; }',
      '#cconsent-bar button { line-height:normal; font-size:14px; border:none; padding:10px 10px; color:' + window.CookieConsent.config.theme.barMainButtonTextColor + '; background-color:' + window.CookieConsent.config.theme.barMainButtonColor + ';}',
      '#cconsent-bar button.ccb__btn { margin-right:5px }',
      '#cconsent-bar a.ccb__edit { margin-right:15px }',
      '#cconsent-bar a:hover, #cconsent-bar button:hover { cursor:pointer; }',
      '#cconsent-bar .ccb__hide {cursor:pointer; font-size:26px; position: absolute; right:15px; top: 15px;}',
      '#cconsent-modal { display:none; font-size:14px; line-height:18px; color:#666; width: 100vw; height: 100vh; position:fixed; left:0; top:0; right:0; bottom:0; font-family:sans-serif; font-size:14px; background-color:rgba(0,0,0,0.6); z-index:9999; align-items:center; justify-content:center;}',
      '@media (max-width: 600px) { #cconsent-modal { height: 100% } }',
      '#cconsent-modal h2, #cconsent-modal h3 {color:#333}',
      '#cconsent-modal.ccm--visible {display:flex}',
      '#cconsent-modal .ccm__content { max-width:600px; min-height:500px; max-height:600px; overflow-Y:auto; background-color:#EFEFEF; }',
      '@media (max-width: 600px) { #cconsent-modal .ccm__content { max-width:100vw; height:100%; max-height:initial; }}',
      '#cconsent-modal .ccm__content > .ccm__content__heading { border-bottom:1px solid #D8D8D8; padding:35px 35px 20px; background-color:#EFEFEF; position:relative;}',
      '#cconsent-modal .ccm__content > .ccm__content__heading h2 { font-size:21px; font-weight:600; color:#333; margin:0 }',
      '#cconsent-modal .ccm__content > .ccm__content__heading .ccm__cheading__close {font-weight:600; color:#888; cursor:pointer; font-size:26px; position: absolute; right:15px; top: 15px; border:none;}',
      '#cconsent-modal h2, #cconsent-modal h3 {margin-top:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body { background-color:#FFF;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup {margin:0; border-bottom: 1px solid #D8D8D8; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head::before { position:absolute; left:35px; font-size:1.4em; font-weight: 600; color:#E56385; content:"×"; display:inline-block; margin-right: 20px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.checked-5jhk .ccm__tab-head::before {font-size:1em; content:"✔"; color:#28A834}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head .ccm__tab-head__icon-wedge { transition: transform .3s ease-out; transform-origin: 16px 6px 0; position:absolute;right:25px; top:50%; transform:rotate(0deg); transform:translateY(-50%)}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head .ccm__tab-head__icon-wedge > svg { pointer-events: none; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-head .ccm__tab-head__icon-wedge {transform:rotate(-180deg)}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head {color:#333; padding:17px 35px 17px 56px; margin:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content {padding:25px 35px; margin:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head { transition: background-color .5s ease-out }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head:hover { background-color:#F9F9F9 }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head {font-weight:600; position:relative;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-content {display:none;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-head { background-color:#F9F9F9 }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-content {display:flex;}',
      '@media (max-width: 600px) { #cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-content {flex-direction:column} }',
      '@media (max-width: 600px) { #cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left { margin-bottom:20px; } }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm_switch-wrapper {position:absolute; right: 35px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch-component {display:flex; margin-right:35px; align-items:center;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch-component > div {font-weight:600;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch-group {width:40px; height:20px; margin:0 10px; position:relative;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch {position: absolute; top:0; right:0; display: inline-block; width: 40px; height: 20px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch input {opacity:0;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch .ccm__switch__slider  {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; border-radius:10px; -webkit-transition: .4s; transition: .4s;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch .ccm__switch__slider:before  {position: absolute; content: ""; height: 12px; width: 12px; left: 4px; bottom: 4px; background-color: white; border-radius:50%; -webkit-transition: .4s; transition: .4s;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch input:checked + .ccm__switch__slider  {background-color: #28A834;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch input:focus + .ccm__switch__slider  {box-shadow:  0 0 0 2px black;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__switch input:checked + .ccm__switch__slider:before  {-webkit-transform: translateX(20px); -ms-transform: translateX(20px); transform: translateX(20px);}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content h3 {font-size:18px; margin-bottom:10px; line-height:1;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content p {color:#444; margin-bottom:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list:not(:empty) {margin-top:30px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list .ccm__list__title {color:#333; font-weight:600;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list ul { margin:15px 0; padding-left:15px }',
      '#cconsent-modal .ccm__content .ccm_table { border-spacing: 0px; border-collapse: collapse; margin: 10px 0px; width: 100%; }',
      '#cconsent-modal .ccm__content .ccm_table thead { background-color:#333; color:white; font-size: 80%;}',
      '#cconsent-modal .ccm__content .ccm_table td { border: 1px solid gray; padding: 3px; font-size: 80% }',
      '#cconsent-modal .ccm__footer { padding:35px; background-color:#EFEFEF; text-align:center; display: flex; align-items:center; justify-content:flex-end; }',
      '#cconsent-modal .ccm__footer button { line-height:normal; font-size:14px; transition: background-color .5s ease-out; background-color:' + window.CookieConsent.config.theme.modalMainButtonColor + '; color:' + window.CookieConsent.config.theme.modalMainButtonTextColor + '; border:none; padding:13px; min-width:110px; border-radius: 2px; cursor:pointer; }',
      '#cconsent-modal .ccm__footer button:hover { background-color:' + Utilities.lightenDarkenColor(window.CookieConsent.config.theme.modalMainButtonColor, -20) + '; }',
      '#cconsent-modal .ccm__footer button#ccm__footer__consent-modal-submit {  margin-right:10px; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.checked-5jhk .status-wrap .status-off {display: none;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup:not(.checked-5jhk) .status-wrap .status-on {display: none;}',
      '#cconsent-external-iframe {width: 1px; height: 1px; display:none;}'
    );
  }

  buildBar() {
    return el('div#cconsent-bar.ccb--hidden', { 'aria-hidden': true },
      el(`div.ccb__wrapper`,
        el('div.ccb__left',
          el('div.cc-text', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barMainText'))
        ),
        el('div.ccb__right',
          el('div.ccb__button',
            (window.CookieConsent.config.useThreeButtonBar || false)
              ? el('button.ccb__edit.ccb__btn', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barLinkSetting'))
              : el('a.ccb__edit', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barLinkSetting'), { href: 'javascript:void(0);' }),
            (window.CookieConsent.config.useThreeButtonBar || false)
              ? el('button.consent-reject.ccb__btn', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barBtnReject')) 
              : null,
            el('button.consent-give', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barBtnAcceptAll'))
          )
        )
      ),
      (window.CookieConsent.config.showCloseButton || false)
        ? el('div.ccb__hide', '×')
        : null
    );
  }

  buildModal() {

    // Cookie names list middleware
    var listCookies = function (category) {
      var list = [];

      for (let service in window.CookieConsent.config.services) {
        (window.CookieConsent.config.services[service].category === category) && list.push(window.CookieConsent.config.services[service]);
      }

      if (list.length) {

        var listItems = [];

        for (let item in list) {
          listItems.push(el('li', Language.getTranslation(list[item], window.CookieConsent.config.language.current, 'name')));
        }

        return [el('div.ccm__list', el('span.ccm__list__title', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalAffectedSolutions')), el('ul', listItems))];
      }
    }


    var renderTableHead = function () {
      return el('thead', el('tr', [
        el('td', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'cookieName')),
        el('td', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'cookiePurpose')),
        el('td', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'cookieExpiration')),
        el('td', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'cookieType'))
      ]))
    }



    var renderTable = function (items) {
      var table = el('table.ccm_table',
        [renderTableHead(), items.map(item => el('tr', [
          el('td', item.cookieName),
          el('td', item.purpose),
          el('td', item.expiration),
          el('td', item.type)
        ]))])

      return table
    }


    var listCookies2 = function (category) {
      var list = [];

      for (let service in window.CookieConsent.config.services) {
        (window.CookieConsent.config.services[service].category === category) && list.push(window.CookieConsent.config.services[service]);
      }

      if (list.length) {

        var listItems = [];

        for (let item in list) {
          var table = "";
          if (list[item] && list[item].language && list[item].language.locale[window.CookieConsent.config.language.current] && list[item].language.locale[window.CookieConsent.config.language.current].items) {
            table = renderTable(list[item].language.locale[window.CookieConsent.config.language.current].items);
          }

          listItems.push(el('li', [Language.getTranslation(list[item], window.CookieConsent.config.language.current, 'name'), table]));
        }

        return [el('div.ccm__list', el('span.ccm__list__title', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalAffectedSolutions')), el('ul', listItems))];
      }
    }

    var renderSwitch = function (key) {
      return (!window.CookieConsent.config.categories[key].needed) && el('div.ccm__switch-component',
        el('span.status-wrap',
          el('div.status-off', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'off')),
          el('div.status-on', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'on')),
        ),
        el('div.ccm__switch-group',
          el('label.ccm__switch', { for: 'switch-' + key },
            el('input.category-onoff', { type: 'checkbox', 'data-category': key, id: 'switch-' + key, 'checked': window.CookieConsent.config.categories[key].checked }),
            el('span.ccm__switch__slider')
          )
        ),
      )
    }



    function modalTabGroups() {

      let contentItems = [];

      let i = 0;
      for (let key in window.CookieConsent.config.categories) {

        contentItems.push(el('dl.ccm__tabgroup' + '.' + key + ((window.CookieConsent.config.categories[key].checked) ? '.checked-5jhk' : ''), { 'data-category': key },
          el('dt.ccm__tab-head', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'name'),
            el('span.ccm_switch-wrapper', renderSwitch(key)),
            window.CookieConsent.config.categories[key].disableExpand !== true 
            ? el('a.ccm__tab-head__icon-wedge', { href: 'javascript:void(0);', 'aria-expanded': false, 'aria-controls': 'content-' + key, role: 'button', title: Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'toggleSectionText') },
              el(document.createElementNS("http://www.w3.org/2000/svg", "svg"), { version: "1.2", preserveAspectRatio: "none", viewBox: "0 0 24 24", class: "icon-wedge-svg", "data-id": "e9b3c566e8c14cfea38af128759b91a3", style: "opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;" },
                el(document.createElementNS("http://www.w3.org/2000/svg", "path"), { 'xmlns:default': "http://www.w3.org/2000/svg", class: "icon-wedge-angle-down", d: "M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z", style: "fill: rgb(51, 51, 51);" })
              )
            ) : null,
          ),
          el('dd.ccm__tab-content', { id: "content-" + key },
            // el('div.ccm__tab-content__left',
            //   (!window.CookieConsent.config.categories[key].needed) && el('div.ccm__switch-component', el('div.status-off', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'off')),
            //     el('div.ccm__switch-group',
            //       el('label.ccm__switch',
            //         el('input.category-onoff', { type: 'checkbox', 'data-category': key, 'checked': window.CookieConsent.config.categories[key].checked }),
            //         el('span.ccm__switch__slider')
            //       )
            //     ),
            //     el('div.status-on', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'on')))
            // ),
            el('div.right',
              el('h3', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'name')),
              el('p', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'description')),
              el('div.ccm__list',
                listCookies2(key)
              )
            )
          )
        )
        );

        i++;
      }

      return contentItems;
    }

    return el('div#cconsent-modal', { 'aria-hidden': true },
      el('div.ccm__content',
        el('div.ccm__content__heading',
          el('h2', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainTitle')),
          el('p',
            Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainText'),
            (window.CookieConsent.config.modalMainTextMoreLink) ? el('a', { href: window.CookieConsent.config.modalMainTextMoreLink, target: '_blank', rel: 'noopener noreferrer' }, Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainTextMore')) : null
          ),
          el('button.ccm__cheading__close', { title: Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'closeButtonText') }, '×')
        ),
        el('div.ccm__content__body',
          el('div.ccm__tabs',
            modalTabGroups()
          )
        ),
        el('div.ccm__footer',
          el('button#ccm__footer__consent-modal-submit', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalBtnSave')),
          el('button.consent-give', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalBtnAcceptAll'))
        )
      )
    );
  }

  modalRedrawIcons() {
    var tabGroups = this.elements['modal'].querySelectorAll('.ccm__tabgroup');

    for (let tabGroup of tabGroups) {
      if (window.CookieConsent.config.categories[tabGroup.dataset.category].checked) {
        if (!tabGroup.classList.contains('checked-5jhk')) {
          tabGroup.classList.add('checked-5jhk');
          tabGroup.querySelector('input.category-onoff').checked = true;
        };
      } else {
        if (tabGroup.classList.contains('checked-5jhk')) tabGroup.classList.remove('checked-5jhk');
        tabGroup.querySelector('input.category-onoff').checked = false;
      }
    }
  }

  render(name, elem, callback) {
    if (typeof callback === 'undefined') callback = function () { };
    if (typeof this.elements[name] !== 'undefined') {
      this.elements[name].parentNode.replaceChild(elem, this.elements[name]);
      this.elements[name] = elem;
      callback(elem);
      return elem;
    } else {
      //var placeholder = document.getElementById('cookie_consent-placeholder')
      var insertedElem = mount(document.body, elem, document.body.firstChild);
      if (insertedElem) {
        this.elements[name] = insertedElem;
      }
      callback(insertedElem);
      return insertedElem;
    }
  }

  buildExternalIframe() {
    return el('iframe#cconsent-external-iframe', { 'aria-hidden': true, 'src': window.CookieConsent.config.externalConfigUrl, 'data-loaded': false, "style": "display:none;" });
  }

  buildIframeInterface(callback) {
    if (typeof callback === 'undefined') callback = function () { };
    var that = this;

    Utilities.ready(function () {
      if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl != undefined) {
        that.render('iframe', that.buildExternalIframe());
      }

      callback();
    });
  }


  buildInterface(callback) {

    if (typeof callback === 'undefined') callback = function () { };
    var that = this;

    Utilities.ready(function () {

      that.render('style', that.buildStyle());

      that.render('modal', that.buildModal());

      that.render('bar', that.buildBar(), (bar) => {

        // Show the bar after a while
        if (!window.CookieConsent.config.cookieExists) {
          setTimeout(() => {
            bar.classList.remove('ccb--hidden');
            bar.setAttribute('aria-hidden', 'false');
          }, window.CookieConsent.config.barTimeout);
        }
      });

      callback();
    });
  }

  addEventListeners(elements) {

    // If you click Accept all cookies
    var buttonConsentGive = document.querySelectorAll('.consent-give');

    for (let button of buttonConsentGive) {
      button.addEventListener('click', () => {

        // We set config to full consent
        for (let key in window.CookieConsent.config.categories) {
          window.CookieConsent.config.categories[key].wanted =
            window.CookieConsent.config.categories[key].checked = true;
        }

        this.writeBufferToDOM();

        this.buildCookie((cookie) => {
          this.setCookie(cookie);
        });

        this.elements['bar'].classList.add('ccb--hidden');
        this.elements['bar'].setAttribute('aria-hidden', 'true');
        this.elements['modal'].classList.remove('ccm--visible');
        this.elements['modal'].setAttribute('aria-hidden', 'true');

        this.modalRedrawIcons();

        this.reRunWrapperScripts()

      });
    }


    // If you click Cookie settings and open modal
    Array.prototype.forEach.call(document.getElementsByClassName('ccb__edit'), (edit) => {
      edit.addEventListener('click', () => {
        this.elements['modal'].classList.add('ccm--visible');
        this.elements['modal'].setAttribute('aria-hidden', 'false');
      });
    });

    // If you click trough the tabs on Cookie settings
    // If you click on/off switch
    this.elements['modal'].querySelector('.ccm__tabs').addEventListener('click', (event) => {

      // If you click trough the tabs on Cookie settings
      if (event.target.classList.contains('ccm__tab-head') || event.target.classList.contains('ccm__tab-head__icon-wedge')) {

        function getDlParent(eventTarget) {
          var parent = eventTarget.parentNode;
          if (parent.nodeName !== 'DL') {
            return getDlParent(parent);
          } else {
            return parent;
          }
        }

        var parentDl = getDlParent(event.target);
        var icon = event.target.classList.contains('ccm__tab-head__icon-wedge') ? event.target : event.target.querySelector('.ccm__tab-head__icon-wedge')

        if (window.CookieConsent.config.categories[parentDl.dataset.category].disableExpand !== true ){
          if (parentDl.classList.contains('ccm__tabgroup--open')) {
            parentDl.classList.remove('ccm__tabgroup--open');
            if (icon != undefined) icon.setAttribute('aria-expanded', false)
          } else {
            parentDl.classList.add('ccm__tabgroup--open');
            if (icon != undefined) icon.setAttribute('aria-expanded', true)
          }
        }
      }

      // If you click on/off switch
      if (event.target.classList.contains('category-onoff')) {
        window.CookieConsent.config.categories[event.target.dataset.category].wanted =
          window.CookieConsent.config.categories[event.target.dataset.category].checked = (event.target.checked === true) ? true : false;

        var dt = document.querySelector('.ccm__tabgroup.' + event.target.dataset.category);
        if (event.target.checked === false && dt.classList.contains('checked-5jhk')) {
          dt.classList.remove('checked-5jhk');
        } else {
          dt.classList.add('checked-5jhk');
        }
      }
    });



    // If you click close on open modal
    this.elements['modal'].querySelector('.ccm__cheading__close').addEventListener('click', (event) => {
      this.elements['modal'].classList.remove('ccm--visible');
      this.elements['modal'].setAttribute('aria-hidden', 'true');
    });


    // If you click close on bottom bar, hide it
    if (window.CookieConsent.config.showCloseButton || false)
      this.elements['bar'].querySelector('.ccb__hide').addEventListener('click', this.saveSettings.bind(this));

    if (window.CookieConsent.config.useThreeButtonBar || false)
      this.elements['bar'].querySelector('.consent-reject').addEventListener('click', this.saveSettings.bind(this));

    // If you click submit on cookie settings
    document.getElementById('ccm__footer__consent-modal-submit').addEventListener('click', this.saveSettings.bind(this))
  }

  addIframeEventListeners() {
    if (window.CookieConsent.config.externalConfigUrl != null) {
      //iframe load
      document.getElementById('cconsent-external-iframe').addEventListener('load', function () {
        this.dataset['loaded'] = true;
      })
    }
  }

  writeBufferToDOM() {

    for (let action of window.CookieConsent.buffer.appendChild) {
      if (window.CookieConsent.config.categories[action.category].wanted === true) {
        Node.prototype.appendChild.apply(action.this, action.arguments);
      }
    }

    for (let action of window.CookieConsent.buffer.insertBefore) {
      if (window.CookieConsent.config.categories[action.category].wanted === true) {
        action.arguments[1] = (action.arguments[0].parentNode === null) ? action.this.lastChild : action.arguments[1];
        Node.prototype.insertBefore.apply(action.this, action.arguments);
      }
    }
  }

  buildCookie(callback) {
    let cookie = {
      version: window.CookieConsent.config.cookieVersion,
      categories: {},
      services: []
    };

    if (window.CookieConsent.config.consentExpiration != undefined) {
      var d = new Date();
      d.setTime(d.getTime() + (window.CookieConsent.config.consentExpiration * 24 * 60 * 60 * 1000));
      cookie.expiration = d.getTime();
    }

    for (let key in window.CookieConsent.config.categories) {
      cookie.categories[key] = {
        wanted: window.CookieConsent.config.categories[key].wanted,
      };
    }
    if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl !== undefined){
      
    }else{
      cookie.services = Utilities.listGlobalServices();
    }

    if (callback) callback(cookie);
    return cookie;
  }

  setCookie(cookie, callback) {
    if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl !== undefined) { // use external config source

      var interval = window.setInterval(() => {
        var externalIframe = document.getElementById('cconsent-external-iframe');
        if (externalIframe != null && externalIframe.dataset['loaded'] == 'true') {
          clearInterval(interval);
          externalIframe.contentWindow.postMessage(`{"action": "SET", "data" : ${JSON.stringify(cookie)}}`, window.CookieConsent.config.externalConfigUrl);
        }
      }, 100)
    }
    else if (window.CookieConsent.config.useLocalStorage == true) { //use localstorage to store setting, if set in config
      localStorage.setItem("cconsent", JSON.stringify(cookie))
    } else { //else do default - save to cookie
      var expiration = "Thu, 01 Jan 2099 00:00:00 UTC";
      if (window.CookieConsent.config.consentExpiration!= undefined){
        var d = new Date();
        d.setTime(d.getTime() + (window.CookieConsent.config.consentExpiration * 24 * 60 * 60 * 1000));
        expiration = d.toUTCString();
      }
      document.cookie = `cconsent=${JSON.stringify(cookie)}; expires=${expiration}; path=/;`;
    }
    if (callback) callback();
  }

  saveSettings() {
    let switchElements = this.elements['modal'].querySelectorAll('.ccm__switch input');

    Array.prototype.forEach.call(switchElements, (switchElement) => {
      window.CookieConsent.config.categories[switchElement.dataset.category].wanted = switchElement.checked;
    });

    this.buildCookie((cookie) => {
      this.setCookie(cookie, () => {
        this.elements['modal'].classList.remove('ccm--visible');
        this.elements['modal'].setAttribute('aria-hidden', 'true')
        this.elements['bar'].classList.add('ccb--hidden');
        this.elements['bar'].setAttribute('aria-hidden', 'true');
      });
    });

    this.writeBufferToDOM();
    this.reRunWrapperScripts()
  }

  reRunWrapperScripts() {
    //console.log('save')
    window.CookieConsent.wrapperFilter.reRunCallbacks()
  }
}
