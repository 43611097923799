import Filter from './Filter';

export default class WrapperFilter extends Filter {

  constructor() {
    super();
    this.callbacks = {}
    this.wrapperRuns = []
  }

  init() {
    this.filterWrappers();
    if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl !== undefined) {
      this.callbacks = window.CookieConsent.callbacks;
      this.wrapperRuns = window.CookieConsent.wrapperRuns;
    }
  }

  filterWrappers() {
    var blacklist = super.createBlacklist('wrapped');

    function wrapper(name = '', callback) {

      this.callbacks[name] = this.callbacks[name] || []
      this.callbacks[name].push(callback)

      //create unique name 
      var namePlusFn = name + "_" + callback.toString();

      if (blacklist.indexOf(name) < 0) {
        if (!this.wrapperRuns.includes(namePlusFn)) {
          //console.log('running ', name)
          callback();
          this.wrapperRuns.push(namePlusFn)
        }
      }
    }

    window.CookieConsent.wrapper = wrapper.bind(this);
  }

  reRunCallbacks() {

    var services = {};
    for (var service in window.CookieConsent.config.services) {
      if (window.CookieConsent.config.services[service].type === 'wrapped') {
        if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].wanted === true) {
          services[service] = window.CookieConsent.config.services[service];
        }
      }
    }

    var wantedServices = Object.keys(services).map(key => services[key].search)
    
    wantedServices.forEach(service => {
      if (this.callbacks[service] != undefined) {
        this.callbacks[service].forEach(cb => {
          //console.log('running async ', service)
          var namePlusFn = service + "_" + cb.toString();
          if (!this.wrapperRuns.includes(namePlusFn)) {
            cb()
            this.wrapperRuns.push(namePlusFn)
          }
        })
      }

    })
  }
}